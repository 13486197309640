#
# Controllers / actions specific javascript
#

import Modal from './modal'
import noUiSlider from 'nouislider'

$.extend true, $.y, {

  controllers:

    users:

      show_act: ->
        zoomModal    = $('#zoom-slider')
        thumbsSlider = $('#preview-slider')
        zoomSlider   = $('#main-zoom-slider')
        navSlider    = $('#nav-zoom-slider')
        $('[data-rating]').barrating theme:'fontawesome-stars-o readonly', readonly: true, initialRating: $('[data-rating]').data('rating')
        $('.rating-events-list').on 'y:domChanged', (e) ->
          for b in $(e.target).find('[data-rating]')
            $(b).barrating theme:'fontawesome-stars-o readonly', readonly: true, initialRating: $(b).data('rating')

        setImageClass  = (image) ->
          iw          = image.width()
          ih          = image.height()
          vw          = zoomSlider.width()
          vh          = zoomSlider.height()
          ratioI      = iw/ih
          ratioV      = vw/vh
          image.removeClass('landscape portrait')

          if iw>vw or ih>vh
            if ratioI < ratioV
              image.addClass('portrait')
            else
              image.addClass('landscape')

        thumbsSlider.slick { lazyload: 'ondemand', arrows: true, prevArrow: '#prev-preview', nextArrow: '#next-preview' }

        zoomSlider.on 'lazyLoaded', (e, slick, image, imageSource) ->
          setImageClass(image)
          image.siblings('.loading').remove()

        $('#trigger-zoom [data-slider-trigger]').on 'click', (e) ->
          e.preventDefault()
          if $('body').hasClass('mobile')
            $('.zoom-slider-picture, .slick-slideshow, .slick-slide, .slick-slide .va-table, .slick-slide .va-row, .slick-slide .va-cell, .slider-loading')\
              .width($(window).width()).height($(window).height())
            iSlide = 0
          else
            zoomSlider.find('.va-cell').width(zoomSlider.width()).height(zoomSlider.height())
            iSlide = thumbsSlider.slick('slickCurrentSlide')
          zoomModal.show()
          zoomSlider.slick { lazyload: 'ondemand', initialSlide: iSlide, arrows: true, prevArrow: '#prev-zoom', nextArrow: '#next-zoom', asNavFor: navSlider }
          navSlider.slick { arrows: false, slidesToShow: 6, slidesToScroll: 1, initialSlide: iSlide, asNavFor: zoomSlider, centerPadding: 0, \
                                        focusOnSelect: true, variableWidth: true, centerMode: false, adaptiveHeight: true, variableWidth: true }

        zoomModal.click (e) ->
          if $(e.target).is('[data-slider-close]')
            e.preventDefault()
            zoomModal.hide()
            navSlider.add(zoomSlider).slick 'unslick'

      search_act: ->
        $.y.controllers.commons.search()

      past_events_act: ->
        alert('dsf')
        $('[data-rating]').barrating theme:'fontawesome-stars-o readonly', readonly: true, initialRating: $('[data-rating]').data('rating')

    events:

      show_act: ->
        $('.main-comment-form').click (e) -> $(e.currentTarget).addClass 'open'

        $('.comments-list').on 'click', '.cta-answer', (e) ->
          e.preventDefault()
          $(e.target).hide().next().show().find('textarea').focus()

        $('.comments-list').on 'y:domChanged', (e) ->
          n = $(e.target)
          n.parent('li').find('.comment-answer > a').show().next().hide().find('textarea').val('') if n.is 'div'

        nFs = $('.return-result')
        $('.input-file').change (e) ->
          nFs.html(I18n.t('js.upload.photo_selected')) unless $(e.target).val() is ''

        $('form[data-multipart]').on('y:upload:progress', (e, loaded, total) ->
          nFs.html(I18n.t('js.upload.sending', { percent: Math.round(loaded / total * 100) })) if loaded isnt 100
        ).on('ajax:complete', -> nFs.html('')).on('ajax:success', (e) -> $(e.target).get(0).reset())

        $('.content-box.event-attendees').on 'y:domChanged', (e) ->
          $('#total-attendees').html $(e.currentTarget).find('.user-row.first .user-box').length

      share_with_friends_act: -> $.y.controllers.account.events.friends_act()

      search_act: ->
        $.y.controllers.commons.search()
        unless $('body').hasClass('mobile') or $('body').hasClass('tablet')
          $('#event_solr_search_date').inputmask(I18n.t('js.inputmask.date'), { placeholder: '_' })
                                                 .datetimepicker({format: I18n.t('js.datepicker.date'), locale: I18n.locale, minDate: Date()})

      rate_act: -> $('[data-rating="1"]').barrating theme:'fontawesome-stars-o', readonly: false

      participate_act: ->
        $('[data-single-friends]').on 'click', '.card--user', (event) ->
          event.preventDefault();

          max_attendee_count = $('#max_attendees').val() + $('#validated_attendees_count').val();
          selected_count = $('[data-single-friends] .card--user.selected').length;
          selected_count += if $(event.currentTarget).hasClass('selected') then -1 else 1;

          if selected_count <= max_attendee_count
            $(event.currentTarget).toggleClass('selected');
            $(event.currentTarget).find(':checkbox').attr 'checked', $(event.currentTarget).hasClass('selected');
          else
            Modal.alert I18n.t('js.events.alert_attendees.title'), I18n.t('js.events.alert_attendees.message');
            return false;


    suggested_events:

      index_act: ->
        $('input').click (e) -> $(e.target).parents('form').submit()

    pages:

      index_act: ->
        $('#sign-in-trigger').click -> $('#header').addClass('sign-in')

        $('#password').keypress (e) ->
          s = String.fromCharCode(e.which)
          $(e.target).parent().toggleClass 'show', s.toUpperCase() is s and s.toLowerCase() isnt s and !e.shiftKey

      map_act: ->
        canvas = $('#map-canvas')
        position = new google.maps.LatLng(canvas.data('lat'), canvas.data('lng'))
        map = new google.maps.Map canvas.get(0), { center: position, zoom: 17 }
        new google.maps.Marker { map: map, position: position }

      direction_act: ->
        canvas = $('#map-canvas')
        panel  = $('#map-panel')

        origin      = new google.maps.LatLng(canvas.data('origin-lat'), canvas.data('origin-lng'))
        destination = new google.maps.LatLng(canvas.data('lat'), canvas.data('lng'))

        map       = new google.maps.Map canvas.get(0)
        direction = new google.maps.DirectionsRenderer()
        direction.setMap map
        direction.setPanel(panel.get(0)) if panel

        new google.maps.DirectionsService().route {origin: origin, destination: destination, travelMode: google.maps.TravelMode.DRIVING}, (response, status) ->
          direction.setDirections(response) if status is google.maps.DirectionsStatus.OK

      help_act: ->
        $('dt').click (e) ->
          adt = $(e.target)
          adt.siblings('dt').removeClass('active')
          adt.next('dd').siblings('dd').removeClass('active').find('dt,dd').removeClass('active')
          adt.toggleClass('active').next('dd').toggleClass('active')
          $('[data-show-form]').show().next().hide()
          e.preventDefault()

        $('[data-show-form]').click (e) ->
          $(e.currentTarget).hide().next().show()
          e.preventDefault()

    commons:

      search: ->
        $('[data-reset-search]').click (e) -> $(e.currentTarget).prev('input').val('').trigger('change')

        # Hobby selection
        $('[data-hobby-type]').change (e) ->
          $('[data-hobbies-selection]').empty() unless $(e.target).val() is 2

        $('[data-hobby-type=2]').click (e) ->
          p = $('[data-hobbies-selection] input').serializeArray()
          h['name'] = 'hobby_ids[]' for h in p
          $.get $(e.target).data('url'), $.param(p), (data, status, xhr) ->
            (new Modal 'content', {content: data, style: 'large'}).show()
            $.y.actionCallbackXhr xhr

        $('[data-hobbies-selection]').click '.delete', (e) -> $(e.target).closest('button').remove()

        # City autocomplete
        autocompleteNode     = $('[data-field="from"]')
        autocompleteSelected = false
        autocompleteNode.keydown (e) ->
          if e.keyCode == 13
            e.preventDefault()
            $(e.target).val('').blur()
        autocompleteNode.change ->
          if autocompleteNode.val() is ''
            $('[data-field="from_lat"]').val ''
            $('[data-field="from_lng"]').val ''
        autocomplete = new google.maps.places.Autocomplete autocompleteNode.get(0), { types: ['(cities)'], componentRestrictions: { country:  ['fr', 'be', 'ch', 'mc', 'lu'] }}
        google.maps.event.addListener autocomplete, 'place_changed', ->
          place = autocomplete.getPlace()
          return if !place.geometry
          $('[data-field="from_lat"]').val place.geometry.location.lat()
          $('[data-field="from_lng"]').val place.geometry.location.lng()

        # Enable/disable age slider
        $('[data-select-kind] input').change (e) ->
          $("#f_age_slider").attr 'disabled', $('[data-field="male"]').is(':checked') && $('[data-select-kind] input:checked').length is 1
          $("#m_age_slider").attr 'disabled', $('[data-field="female"]').is(':checked') && $('[data-select-kind] input:checked').length is 1

        # Slider formaters
        distanceFormater = (v) -> if v is 0 then I18n.t('js.search.everywhere') else I18n.t('js.search.distance', { distance: v })
        ageFormater =  (v) -> I18n.t('js.search.age', { age: Math.round(v) })

        # Distance slider
        noUiSlider.create($('#distance_slider').get(0), {
          start: $('[data-field="distance"]').val(), connect: [true,false],
          step: 5, range: { min: 0, max: 100 }, tooltips: { to: distanceFormater },
          pips: { mode: 'count', values: 5, density: 5, format: { to: distanceFormater }}
        }).on('update', (values, handle) -> $('[data-field="distance"]').val(values[handle]))

        # Age sliders
        for type in ['f', 'm']
          noUiSlider.create($("##{type}_age_slider").get(0), {
            start: [$("[data-field=\"#{type}_age_min\"]").val(), $("[data-field=\"#{type}_age_max\"]").val()],
            connect:true, step: 1, range:{ min: 18, max: 100 }, tooltips: [{ to: ageFormater }, { to: ageFormater }]
          }).on('update', (values, handle) ->
            $(this.target).parent('.form-slider-group').find('input[type="hidden"]').eq(handle).val(Math.round(values[handle]))
            $(this.target).find(".noUi-handle").eq(handle).attr('data-label', Math.round(values[handle]))
          )

}, true
